<template>
  <component :is="is" :class="[componentClass, btnSize]" :href="href" :type="computedType" :to="to" :target="target" :disabled="disabled">
    <component v-if="icon" :class="[size]" class="fill-current grow" :is="icon" />
    <span v-if="!loading" :class="labelClass">{{ label }}</span>
    <div role="status" v-if="loading">
      <spinner />
      <span class="sr-only">Loading...</span>
    </div>
  </component>
</template>

<script>
import { getButtonColor } from '@/utils/colors.js';
import Spinner from '@/components/Spinner.vue';

export default {
  component: { Spinner },
  props: {
    size: [String],
    label: {
      type: [String, Number],
      default: null,
    },
    icon: {
      type: Object,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    color: {
      type: [String],
      default: 'white',
    },
    as: {
      type: String,
      default: null,
    },
    small: Boolean,
    outline: Boolean,
    full: Boolean,
    medium: Boolean,
    active: Boolean,
    disabled: Boolean,
    loading: Boolean,
    btnSize: String,
    pointer: Boolean,
  },

  computed: {
    is() {
      if (this.as) {
        return this.as;
      }

      if (this.to) {
        return 'router-link';
      }

      if (this.href) {
        return 'a';
      }

      return 'button';
    },
    computedType() {
      if (this.is === 'button') {
        return this.type ?? 'button';
      }

      return null;
    },
    labelClass() {
      return this.icon && !this.label ? 'px-0' : this.small && this.icon ? 'px-1' : 'px-2';
    },
    componentClass() {
      const base = [
        'flex',
        this.pointer ? 'cursor-not-allowed cursor-none' : 'cursor-pointer',
        'justify-center',
        'items-center',
        'whitespace-nowrap',
        'focus:outline-none',
        'transition-colors',
        'focus:ring-0',
        'duration-150',
        'text-base font-semibold',
        this.active ? 'ring ring-black' : 'focus:ring-0',
        this.small ? 'p-2 rounded-full' : 'p-3 rounded-xl',
        this.outline ? 'border-2' : 'border-0',
        this.full ? 'w-full' : '',
        this.medium ? 'w-40' : '',

        getButtonColor(this.color, this.outline, !this.disabled),
      ];

      if (this.disabled) {
        base.push('cursor-not-allowed', this.outline ? 'opacity-50' : 'opacity-70');
      }

      return base;
    },
  },
};
</script>
