<template>
  <div class="w-full h-full flex items-center justify-center z-50 fixed inset-0 bg-overlay">
    <div class="absolute inset-0 h-full w-full cursor-pointer z-10 backdrop-blur-sm" @click="$emit('hideOverlay')"></div>
    <div class="rounded-xl bg-brand-black p-5 md:p-0 md:w-[500px] md:h-[230px] flex flex-col items-center justify-center space-y-5 z-50">
      <div class="w-[350px] text-center space-y-1">
        <h2 class="text-[white] font-bold text-2xl">Sign {{ type == 'login' ? 'in' : 'up' }} to continue</h2>
        <p class="text-xs text-[white]">
          Sign {{ type == 'login' ? 'in' : 'up' }} to get access to all the features we’ve built just for you at Careerbuddy.
        </p>
      </div>
      <div class="flex space-x-5 mt-8">
        <template v-if="type == 'login'">
          <a
            :href="`${$employerUrl}/login`"
            rel="noopener noreferrer"
            class="border-2 border-[white] rounded-xl inline-block py-4 px-8 text-[white] capitalize"
            >Employer login</a
          >
          <a
            :href="`${$candidateUrl}/login`"
            rel="noopener noreferrer"
            class="border-2 border-[white] rounded-xl inline-block py-4 px-8 text-[white] capitalize"
            >Candidate login</a
          >
        </template>
        <template v-else>
          <a
            :href="`${$employerUrl}/register`"
            rel="noopener noreferrer"
            class="border-2 border-[white] rounded-xl inline-block py-4 px-8 text-[white] capitalize"
            >Employer Signup</a
          >
          <button>
            <a
              :href="`${$candidateUrl}/register`"
              rel="noopener noreferrer"
              class="border-2 border-[white] rounded-xl inline-block py-4 px-8 text-[white] capitalize"
              >Candidate Signup</a
            >
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'login',
    },
  },
};
</script>

<style>
.overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(8px);
  z-index: 10;
}
</style>
