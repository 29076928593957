import ButtonVue from '../Button.vue';
<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <Disclosure as="nav" class="sticky top-0 z-30 bg-[#FEFAF1] xl:flex justify-center" v-slot="{ open }">
      <div class="px-[24px] md:px-0 mx-auto md:w-[82vw] header-wrap">
        <div class="relative py-3">
          <div class="absolute inset-y-0 right-0 flex items-center xl:hidden">
            <!-- Mobile menu button-->
            <DisclosureButton
              class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <router-link to="/">
                <img class="h8 w-[185px] lg:block" src="@/assets/images/logo.png" alt="CareerBuddy" />
              </router-link>
            </div>

            <div class="hidden sm:ml-6 xl:block">
              <div class="flex items-center space-x-4">
                <Menu v-for="(item, index) in navigation" :key="index">
                  <div class="relative">
                    <MenuButton class="mx-3 text-[16px] inline-flex text-black/60 hover:text-dark" @click="route.push(item.href)"
                      >{{ item.name }} <img v-if="item.subnav" src="@/assets/icons/ArrowDown.svg" class="ml-2" alt=""
                    /></MenuButton>
                    <MenuItems v-if="item.subnav" class="absolute left-2 top-8 z-10 bg-white p-2 px-2 rounded-lg shadow-lg w-full">
                      <template v-for="subnav in item.subnav" :key="subnav">
                        <MenuItem as="p" class="py-1">
                          <a
                            :href="subnav.href"
                            :class="[
                              subnav.current ? 'text-black' : 'text-black/80 hover:text-dark',
                              'px-1 text-[14px] block text-center truncate mb-4',
                            ]"
                            :aria-current="subnav.current ? 'page' : undefined"
                            >{{ subnav.name }}
                          </a>
                        </MenuItem>
                      </template>
                    </MenuItems>
                  </div>
                </Menu>
              </div>
            </div>

            <div class="hidden xl:inline-block">
              <button
                class="mr-4 px-6 py-3 text-brand-black text-base font-bold"
                @click="
                  overlay = true;
                  overlayType = 'signup';
                "
              >
                Log in
              </button>
              <button
                class="mr-4 px-6 py-3 border border-brand-black bg-brand-black text-white text-base font-bold rounded-xl"
                @click="
                  overlay = true;
                  overlayType = 'login';
                "
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel class="bg-white absolute left-0 top-[48px] right-0 xl:hidden">
        <div class="space-y-1 px-2 pt-2 pb-3 w-auto md:w-[82vw] mx-auto">
          <Menu v-for="(item, index) in navigation" :key="index">
            <div class="relative pr-[24px]">
              <MenuButton class="mx-3 py-2 text-[16px] w-full flex justify-between text-black/60 hover:text-dark"
                >{{ item.name }} <img v-if="item.subnav" src="@/assets/icons/ArrowDown.svg" class="ml-2" alt=""
              /></MenuButton>
              <MenuItems class="absolute left-2 top-8 z-10 bg-white p-2 px-2 rounded-lg shadow-lg w-3/4">
                <template v-for="subnav in item.subnav" :key="subnav">
                  <MenuItem as="p" class="py-1">
                    <a
                      :href="subnav.href"
                      :class="[subnav.current ? 'text-black' : 'text-black/80 hover:text-dark', 'px-1 text-[14px] block text-center truncate mb-4']"
                      :aria-current="subnav.current ? 'page' : undefined"
                      >{{ subnav.name }}
                    </a>
                  </MenuItem>
                </template>
              </MenuItems>
            </div>
          </Menu>

          <br />

          <div class="flex items-center px-3 my-8">
            <Button
              label="Sign up"
              color="black"
              class="ml12"
              @click="
                overlay = true;
                overlayType = 'signup';
              "
            />
            <Button
              label="Log in"
              color="black"
              outline
              class="ml-4"
              @click="
                overlay = true;
                overlayType = 'login';
              "
            />
          </div>

          <br />
        </div>
      </DisclosurePanel>
    </Disclosure>
  </transition>
  <Overlay v-if="overlay" :type="overlayType" @hide-overlay="overlay = false" />
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import Overlay from '@/components/partials/AccountOverlay.vue';
import Button from '@/components/Button.vue';
import Nav from '@/assets/icons/nav.svg?inline';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useRoute } from 'vue-router';
import gsap from 'gsap';

const route = useRoute();
const candidate_url = ref(process.env.VUE_APP_CANDIDATE_URL);
const employer_url = ref(process.env.VUE_APP_EMPLOYER_URL);
const overlay = ref(false);
const overlayType = ref('login');

const setCurrent = (value) => {
  return route.path === value;
};

const navigation = ref([
  //{ name: 'Home', href: '/', current: setCurrent('/') },
  //{ name: 'Build CV', href: '/cv', current: setCurrent('/cv') },
  {
    name: 'Platform',
    subnav: [
      { name: 'Employers', href: '/employer', current: setCurrent('/employer') },
      { name: 'Talent Placement', href: '/talent-placement', current: setCurrent('/talent-placement') },
      { name: 'Outsourcing Service', href: '/outsourcing', current: setCurrent('/outsourcing') },
      { name: 'Embedded Recruiter', href: '/embedded-recruiter', current: setCurrent('/embedded-recruiter') },
      { name: 'Talent Marketplace', href: '/talent-marketplace', current: setCurrent('/talent-marketplace') },
      { name: 'Employer Branding', href: '/employer-branding', current: setCurrent('/employer-branding') },
      { name: 'Promote Your Brand', href: '/promote-brand', current: setCurrent('/promote-brand') },
    ],
  },
  {
    name: 'Why CareerBuddy',
    subnav: [
      { name: 'Find Your Next Job', href: '/talents', current: setCurrent('/talents') },
      { name: 'Open Jobs', href: '/jobs', current: setCurrent('/jobs') },
      { name: 'Write Your CV', href: '/cv', current: setCurrent('/cv') },
    ],
  },
  {
    name: 'Pricing',
    href: '/pricing',
    current: setCurrent('/pricing'),
  },
  {
    name: 'Resources',
    subnav: [
      { name: 'About US', href: '/talents', current: setCurrent('/') },
      { name: 'Blog', href: 'https://blog.thecareerbuddy.com/', current: setCurrent('') },
    ],
  },
  // {
  //   name: 'Blog',
  //   href: 'https://blog.thecareerbuddy.com/',
  //   current: setCurrent('https://blog.thecareerbuddy.com/'),
  // },
]);
</script>

<script>
export default {
  data() {
    return {
      showNav: false,
    };
  },
  methods: {
    toggleNav() {
      this.showNav = !this.showNav;
    },
    beforeEnter(el) {
      el.style.opacity = '0';
      el.style.transform = 'translateY(-100px)';
    },
    // where the animation will end up
    enter(el) {
      gsap.to(el, {
        duration: 3,
        // delay: 1,
        y: 0,
        opacity: 1,
        ease: 'back.out',
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.router-link-exact-active {
  color: #1a1a1a;
  font-weight: 500;
}
</style>
